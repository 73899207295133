import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-modify',
  templateUrl: './user-modify.component.html',
  styleUrls: ['./user-modify.component.css']
})
export class UserModifyComponent implements OnInit {
  private subscription:Subscription;
  user:any={
    RemainTimes:0
  };
  constructor(
    private userService:UserService,
    private route:ActivatedRoute
  ) { 
    this.subscription = this.userService.get().subscribe(function (data) {
      setTimeout(function () {
          window.history.back();
      }, 1000);
  });
  }

  ngOnInit() {
    var _this = this;
    this.route.queryParams.subscribe(function (params) {
        _this.user = {...params};
    });
  }
  onSubmit(){
    this.user.RemainTimes = this.user.RemainTimes || 0;
    this.userService.ModifyUser({ userid: this.user.ID, remians: this.user.RemainTimes });
  }
  

}
