import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from '../shared/service/http.service';
import { MessageService } from '../shared/service/message.service';
import { ConfirmService } from '../shared/service/confirm.service';
import { UtilService } from '../shared/service/util.service';
import { FileDownloadService } from '../shared/service/file-download.service';
import AppHttpApiURL from 'src/config/app.url.config';

@Injectable({
  providedIn: 'root'
})
export class PrizeService {
  private prize$:Subject<any>=new Subject<any>();
  state:any={
    prizes: [],
    statistics:[],
    orderby: '',
    ordertype: 0,
    keywords: '',
    pageindex: 1,
    pagesize: 10,
    datacount: 0,
    status: -1,
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  constructor(
    private httpService:HttpService,
    private messageService:MessageService,
    private confirmService:ConfirmService,
    private util:UtilService,
    private fileDown:FileDownloadService
  ) { 

  }
  get(){
    return this.prize$.asObservable();
  }
  list(){
    let _this = this;
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end
    }=this.state;
    return this.httpService.get(AppHttpApiURL.prize.prizes, {
        keywords: keywords,
        pageindex: pageindex,
        pagesize: pagesize,
        orderby:orderby,
        ordertype:ordertype,
        register_date_start:register_date_start,
        register_date_end:register_date_end,
        lastuse_date_start:lastuse_date_start,
        lastuse_date_end:lastuse_date_end,
    }).subscribe(data=> {
      let now=new Date();
      data.data=data.data.map(item=>{
        let effectiveDateStart=this.util.ParseDateTime(item.PrizeEffectiveTimeStart);
        let effectiveDateEnd=this.util.ParseDateTime(item.PrizeEffectiveTimeEnd);
        if(effectiveDateEnd<now){
          //已过期
          item.status=1;
        }else if(item.PrizeCountRemain<=0){
          //已抽完
          item.status=2;
        }else{
          item.status=0;
        }
        return item;
      });
      let nextState = {..._this.state,  prizes: data.data, datacount: data.code };
      _this.state = Object.assign(_this.state, nextState);
      _this.prize$.next(_this.state);
    });
  }
  statistics(){
    let _this = this;
    return this.httpService.get(AppHttpApiURL.prize.statistics, {}).subscribe(data=> {
      let now=new Date();
      let nextState = {..._this.state,  statistics:data.data };
      _this.state = Object.assign(_this.state, nextState);
      _this.prize$.next(_this.state);
    });
  }
  onPageChange(pagination){
    let nextState = Object.assign(this.state,pagination);
    this.state = nextState;
    this.list();
  }
  onSearch(queryKey){
    let nextState = {...this.state,  currentQueryKey: queryKey };
    this.state = nextState;
    this.list();
  }
  AddPrize(prize){
    let _this = this;
    this.httpService.post(AppHttpApiURL.prize.add, null, { params: {...prize} }).subscribe(function (data) {
        if (data.code > 0) {
            _this.messageService.open('添加成功！', 'success');
            _this.prize$.next({ isAddSuccess: true });
        }
        else {
            _this.messageService.open(data.message, 'error');
        }
    });
  }
  DelPrize(groupno){
    let _this = this;
    this.confirmService.open({
        content: '您确认要删除该奖品?',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.prize.del, null, { params: { groupno: groupno } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.state.datacount = _this.state.datacount - 1;
            _this.list();
        },
        active: true
    });
  }
  ExportPrize(type){
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end,
    }=this.state;
    let params:any={
      keywords,pageindex,pagesize,orderby,
      ordertype,register_date_start,register_date_end,
      lastuse_date_start,lastuse_date_end
    }
    params={pagesize:100000000};
    let url='';
    if(type==1){
      //导出已抽取
      url=AppHttpApiURL.prize.export
    }else{
      //导出所有
      url=AppHttpApiURL.prize.export_all;
    }
    this.fileDown.DownLoadFile(url,params);
  }
}
