import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TabsService } from '../home/tabs/tabs.service';
declare var laydate;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  private subscription:Subscription;
  pageIndex = 1;
  pageSize = 15;
  dataCount = 0;
  keyWords = '';
  orderby = '';
  ordertype = 0;
  status = -1;
  filterDate={
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  searchPlaceholder = '工号';
  userList:any=[];
  constructor(
    private userService:UserService,
    private router:Router,
    private tabService:TabsService,
  ) { 
    let _this=this;
    this.subscription = this.userService.get().subscribe(function (data) {
      console.log('user component subscribe,data=',data);
      let {
        users,
        pageindex,
        pagesize,
        datacount,
        register_date_start,
        register_date_end,
        lastuse_date_start,
        lastuse_date_end
      }=data;
      _this.userList = users;
      _this.pageIndex = pageindex;
      _this.dataCount = datacount;
      _this.pageSize = pagesize;
      _this.filterDate={
        register_date_start:register_date_start,
        register_date_end:register_date_end,
        lastuse_date_start:lastuse_date_start,
        lastuse_date_end:lastuse_date_end
      };
    });
  }

  ngOnInit() {
    this.userService.list();
    this.bindLayDate();
  }
  onOrderChange(orderFiled){
    this.orderby = orderFiled;
    this.ordertype = this.ordertype > 0 ? 0 : 1;
    this.userService.onPageChange({ orderby: this.orderby, ordertype: this.ordertype });
  }
  onDateInputChange(key, value){
    this.filterDate[key] = value;
  }
  onSearchSubmit(){
    this.pageIndex=1;
    this.userService.onPageChange({
      ...this.filterDate, 
      keywords: this.keyWords,
      pageindex: 1, 
      pagesize: this.pageSize,
      status: this.status 
      });
  }
  onSearchChange(searchKeyword){
    this.keyWords = searchKeyword;
    this.userService.onPageChange({ keywords: searchKeyword, pageindex: 1 });
  }
  onPageChange(currentPage){
    this.userService.onPageChange({ pageindex: currentPage });
  }
  onDisableUser(userid){
    this.userService.DisableUser(userid);
  }
  onActiveUser(userid){
    this.userService.ActiveUser(userid);
  }
  onUnLock(userid){
    this.userService.UnLockUser(userid);
  }
  onDelUser(userid){
    this.userService.DelUser(userid);
  }
  onShowRecord(employee){
    this.router.navigate(['record/records/' + employee]);
  }
  onModifyUser(user){
    this.router.navigate(['user/modify/' + user.ID], { queryParams:{...user} });
  }
  onAddUserClick(){
    this.router.navigate(['/user/add']);
    this.tabService.changeBreadcrumb('添加员工');
  }
  onExport(type){
    this.userService.ExportUser(type);
  }
  bindLayDate(){
    let that = this;
    laydate.render({
        elem: '#registerDateStart',
        done: function (value, date, endDate) {
            that.filterDate.register_date_start = value;
        }
    });
    laydate.render({
        elem: '#registerDateEnd',
        done: function (value, date, endDate) {
            that.filterDate.register_date_end = value;
        }
    });
    laydate.render({
        elem: '#LastUseDateStart',
        done: function (value, date, endDate) {
            that.filterDate.lastuse_date_start = value;
        }
    });
    laydate.render({
        elem: '#LastUseDateEnd',
        done: function (value, date, endDate) {
            that.filterDate.lastuse_date_end = value;
        }
    });
  }
}
