import { Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { RoomService } from '../room.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-room-modify',
  templateUrl: './room-modify.component.html',
  styleUrls: ['./room-modify.component.css']
})
export class RoomModifyComponent implements OnInit {
  private subscription:Subscription
  room:any={};
  constructor(
    private route:ActivatedRoute,
    private roomService:RoomService
  ) { 
    this.subscription = this.roomService.get().subscribe(function (data) {
      if (data.isSuccess) {
          setTimeout(function () {
              window.history.back();
          }, 1000);
      }
    });
  }

  ngOnInit() {
    let _this=this;
    this.route.queryParams.subscribe(function (params) {
      _this.room = {...params};
    });
  }
  onSubmit(){
    this.room.RemainTimes = this.room.RemainTimes || 1;
    this.roomService.ModifyRoom({
        roomid: this.room.ID,
        roomname: this.room.RoomName,
        roomaddress: this.room.RoomAddress,
        roomdesc: this.room.RoomDesc,
        limitcount: this.room.LimitCount,
        viewid: this.room.ViewId,
        enteruid: this.room.EnterUID,
        leaveuid: this.room.LeaveUID,
        sn: this.room.SN
    });
  }
  

}
