import { Component,Output,Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Input() placeholderText:string='';
  @Input() onSelect:Function;
  @Input() selected:{};
  @Output() onChange:EventEmitter<any>=new EventEmitter();
  interval=undefined;
  constructor() { }

  ngOnInit() {
  }
  onKeyWordChange(keyword){
    var _this = this;
    clearTimeout(this.interval);
    this.interval = setTimeout(()=>{
      _this.onChange.emit(keyword);
    },500);
  }

}
