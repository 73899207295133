import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabsService } from './tabs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  private curBreadCrumb;
  private subscription:Subscription
  constructor(
    private router:Router,
    private tabsService:TabsService
  ) { }

  ngOnInit() {
    this.curBreadCrumb = this.tabsService.all();
  }
  ngAfterViewInit(){
    this.subscription = this.tabsService.get().subscribe(curBreadCrumb=>{
      this.curBreadCrumb = curBreadCrumb;
    });
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  onClickHandler(breadCrumb, e){
    this.router.navigate([breadCrumb.link]);
    this.tabsService.changeBreadcrumb(breadCrumb.name);
    e.stopPropagation();
    e.preventDefault();
  }
  changeTab(menu, e){
    this.tabsService.change({
      id: menu.id,
      name: menu.name,
      link: menu.link,
      active: false,
      outlet: menu.outlet
    });
  }
  closeTab(menu, e){
    this.tabsService.remove({
      id: menu.id,
      name: menu.name,
      link: menu.link,
      active: false,
      outlet: menu.outlet
    });
    e.stopPropagation();
    e.preventDefault();
  }
  
}
