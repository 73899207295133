import { Injectable } from '@angular/core';
import { MessageService } from '../shared/service/message.service';
import { ConfirmService } from '../shared/service/confirm.service';
import { Subject } from 'rxjs';
import { HttpService } from '../shared/service/http.service';
import AppHttpApiURL from 'src/config/app.url.config';
import { FileDownloadService } from '../shared/service/file-download.service';

@Injectable()
export class RecordService {
  private record$:Subject<any>=new Subject();
  state={
    records:[],
    orderby:'',
    ordertype: 0,
    keywords: '',
    pageindex: 1,
    pagesize: 15,
    datacount: 0,
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  constructor(
    private httpService:HttpService,
    private messageService:MessageService,
    private confirmService:ConfirmService,
    private fileDown:FileDownloadService
  ) { }
  get(){
    return this.record$.asObservable();
  }
  list(){
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end
    }=this.state;
    return this.httpService.get(AppHttpApiURL.record.records,{
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end
    }).subscribe(data=>{
      let nextState={
        ...this.state,
        records:data.data,
        datacount:data.code
      };
      this.state=Object.assign(this.state,nextState);
      this.record$.next(this.state);
    })
  }
  onPageChange(pagination){
    let nextState=Object.assign(this.state,pagination);
    this.state = nextState;
    this.list();
  }
  onSearch(queryKey){
    let nextState={
      ...this.state,
      currentQueryKey: queryKey
    };
    this.state = nextState;
    this.list();
  }
  onResetRecordStatus(id,status){
    let _this = this;
    let message="";
    if(status == 1){
      message="您确认要将该记录变更为正常？";
    }
    if(status == 3){
      message="您确认要删除该记录？";
    }
    if(!message){
      return;
    }
    this.confirmService.open({
        content: message,
        operate: function () {
            _this.httpService.post(AppHttpApiURL.record.update_status, null, { params: { id:id,status:status } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.list();
        },
        active: true
    });
  }
  ExportUser(type){
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end,
    }=this.state;
    let params:any={
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end
    };
    if (type == 3) {
      //导出历史所有
      params = { pagesize: 10000 };
      debugger;
      this.fileDown.DownLoadFile(AppHttpApiURL.record.export_history, params);
      return;
    }
    else if (type == 2) {
        //导出所有页
        params = {
            keywords: keywords,
            pageindex: pageindex,
            pagesize: 1000000,
            register_date_start: register_date_start,
            register_date_end: register_date_end,
            lastuse_date_start: lastuse_date_start,
            lastuse_date_end: lastuse_date_end,
            status: status
        };
    }
    else {
        //导出当前页
    }
    this.fileDown.DownLoadFile(AppHttpApiURL.record.export, params);
  }
}
