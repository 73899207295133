import { Injectable, EventEmitter } from '@angular/core';

export interface Confirm {
  content: string;
  operate:Function;
  active:boolean;
}

@Injectable()
export class ConfirmService {
  private confirm$:EventEmitter<any>=new EventEmitter();
  confirm:Confirm={
    content:'',
    operate:undefined,
    active:false
  };
  constructor() { }
  open(confirm){
    this.confirm = confirm;
    this.confirm$.next(this.confirm);
  }
  close(){
    this.confirm.active = false;
    this.confirm$.next(this.confirm);
  }
  get(){
    return this.confirm$.asObservable();
  }
}
