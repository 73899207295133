import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from '../user.component';
import { UserAddComponent } from '../user-add/user-add.component';
import { UserModifyComponent } from '../user-modify/user-modify.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path: 'user',
      component: UserComponent
  },
  {
      path: 'user/add',
      component: UserAddComponent
  },
  {
      path: 'user/modify/:id',
      component: UserModifyComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ],
  declarations: []
})
export class UserRoutingModule { }
