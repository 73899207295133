import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SetService } from '../set.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lock-time',
  templateUrl: './lock-time.component.html',
  styleUrls: ['./lock-time.component.css']
})
export class LockTimeComponent implements OnInit {
  private subscription:Subscription
  hours:number;
  constructor(
    private route:ActivatedRoute,
    private setService:SetService
  ) {
    let _this=this;
    this.subscription = this.setService.get().subscribe(function (data) {
      _this.hours = data.lockTime;
      console.log(data.lockTime);
    });
   }

  ngOnInit() {
    this.setService.getLockTime();
  }
  onSubmit(){
    this.setService.modifyLockTime(this.hours);
  }
}
