import { Component, OnInit } from '@angular/core';
import { RoomService } from './room.service';
import { Router } from '@angular/router';
import { TabsService } from 'src/app/home/tabs/tabs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {
  private subscription:Subscription;
  roomList=[];
  pageIndex=1;
  pageSize=20;
  dataCount = 0;
  keyWords = '';
  orderby = '';
  ordertype = 0;
  searchPlaceholder = '名称/简介/区域';
  constructor(
    private roomService:RoomService,
    private router:Router,
    private tabService:TabsService,
  ) { 
    this.subscription=this.roomService.get().subscribe(data=>{
      console.log('room component,subscribe');
      let {paegindex,datacount,pagesize,rooms}=data;
      this.roomList=rooms;
      this.pageIndex=paegindex;
      this.dataCount=datacount;
      this.pageSize=pagesize;
      console.log('room component,subscribe,rooms=',rooms);
    })
  }

  ngOnInit() {
    this.roomService.list();
  }
  onSearchChange(searchKeyword){
    this.roomService.onPageChange({ keywords: searchKeyword, pageindex: 1 });
  }
  onPageChange(currentPage){
    this.roomService.onPageChange({ pageindex: currentPage });
  }
  onDisableRoom(roomid){
    this.roomService.DisableRoom(roomid);
  }
  onActiveRoom(roomid){
    this.roomService.ActiveRoom(roomid);
  }
  onDelRoom(roomid){
    this.roomService.DelRoom(roomid);
  }
  onModifyRoom(room){
    this.router.navigate(['room/modify/' + room.ID], { queryParams: {...room} });
  }
  onAddRoomClick(){
    this.router.navigate(['/room/add']);
    this.tabService.changeBreadcrumb('添加房间');
  }
  onSetRoomVip(roomid){
    this.roomService.SetRoomAsVip(roomid);
  }
  onCancelRoomVip(roomid){
    this.roomService.CancelRoomAsVip(roomid);
  }
  onUserManage(room){
    this.router.navigate(['/room/vip/'+room.ID],{ queryParams: {...room} });
    this.tabService.changeBreadcrumb('房间用户管理');
  }
}
