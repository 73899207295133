import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Loading{
  active:boolean
}

@Injectable()
export class LoadingService {
  private loading$:Subject<any>=new Subject();
  loading:Loading;
  timeout:number;
  constructor() { }

  open(){
    clearTimeout(this.timeout);
    this.loading = {
        active: true
    };
    this.loading$.next(this.loading);
  }
  close(){
    this.loading = { active: false };
    this.loading$.next(this.loading);
  }
  get(){
    return this.loading$.asObservable();
  }
}
