import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/service/storage.service';
import { TabsService } from '../tabs/tabs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  private subscription:Subscription;
  private username='';
  private menus=[{
          name: '员工管理',
          icon: 'person',
          isOpenSub: false,
          subMenu: [
              {
                  name: '员工列表',
                  link: '/user'
              },
              {
                  name: '添加员工',
                  link: '/user/add'
              }
          ]
      },
      {
          name: '房间管理',
          icon: 'store',
          isOpenSub: false,
          subMenu: [
              {
                  name: '房间列表',
                  link: '/room'
              },
              {
                  name: '添加房间',
                  link: '/room/add'
              }
          ]
      },
      {
          name: '使用记录',
          icon: 'materials',
          link: '/record/records'
      },
      {
        name: '留言板',
        icon: 'evaluate',
        isOpenSub: false,
        subMenu: [
            {
                name: '留言列表',
                link: '/guestbook'
            }
        ]
      },
    //   {
    //     name: '摄像头记录',
    //     icon: 'materials',
    //     isOpenSub: false,
    //       subMenu: [
    //           {
    //               name: '捕捉记录日志',
    //               link: '/camera/log'
    //           },
    //           {
    //               name: '进出门记录',
    //               link: '/camera/pass'
    //           }
    //       ]
    //   },
      {
        name: '奖品管理',
        icon: 'jiangpin',
        isOpenSub: false,
        subMenu: [
            {
                name: '奖品列表',
                link: '/prize'
            },
            {
                name: '添加奖品',
                link: '/prize/add'
            }
        ]
      },
      {
        name: '评价管理',
        icon: 'evaluate',
        isOpenSub: false,
        subMenu: [
            {
                name: '评价列表',
                link: '/evaluate'
            }
        ]
      },
      {
          name: '系统配置',
          icon: 'set',
          isOpenSub: false,
          subMenu: [
              {
                  name:'首页提示',
                  link:'/set/tips'
              },
              {
                  name: '每日限时',
                  link: '/set/limit'
              },
              {
                name: '每日限时(专属房间用户)',
                link: '/set/limitvip'
                },
              {
                  name: '每日重置时间',
                  link: '/set/resettime'
              },
              {
                  name: '违规锁定时长',
                  link: '/set/locktime'
              },
              {
                  name: '超级密码',
                  link: '/set/superpwd'
              }
          ]
      }
  ];
  constructor(
    private router:Router,
    private storageService:StorageService,
    private tabsService:TabsService
  ) { 
    this.subscription=this.storageService.get().subscribe(user=>{
      let username=user.username;
      this.username=username;
    });
  }

  ngOnInit() {
    this.init();
  }
  init(){
    this.username = this.storageService.getState().username;
  }
  onClickHandler(menu){
    if (menu.link) {
      this.router.navigate([menu.link]);
      this.tabsService.changeBreadcrumb(menu.name);
  }
  else {
      menu.isOpenSub = !menu.isOpenSub;
      if (menu.isOpenSub) {
          this.menus.map(function (val) {
              if (menu.name != val.name) {
                  val.isOpenSub = false;
              }
          });
      }
  }
  }
}
