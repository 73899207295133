import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import AppHttpApiURL from 'src/config/app.url.config';
import { HttpService } from '../shared/service/http.service';
import { MessageService } from '../shared/service/message.service';
import { ConfirmService } from '../shared/service/confirm.service';
import { UtilService } from '../shared/service/util.service';
import { FileDownloadService } from '../shared/service/file-download.service';

@Injectable()
export class UserService {
  private user$:Subject<any>=new Subject<any>();
  state:any={
    users: [],
    orderby: '',
    ordertype: 0,
    keywords: '',
    pageindex: 1,
    pagesize: 20,
    datacount: 0,
    status: -1,
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  constructor(
    private httpService:HttpService,
    private messageService:MessageService,
    private confirmService:ConfirmService,
    private util:UtilService,
    private fileDown:FileDownloadService
  ) { 

  }
  get(){
    return this.user$.asObservable();
  }
  list(){
    let _this = this;
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end,
      status
    }=this.state;
    return this.httpService.get(AppHttpApiURL.user.users, {
        keywords: keywords,
        pageindex: pageindex,
        pagesize: pagesize,
        orderby:orderby,
        ordertype:ordertype,
        register_date_start:register_date_start,
        register_date_end:register_date_end,
        lastuse_date_start:lastuse_date_start,
        lastuse_date_end:lastuse_date_end,
        status:status
    }).subscribe(data=> {
        let nextState = {..._this.state,  users: data.data, datacount: data.code };
        _this.state = Object.assign(_this.state, nextState);
        console.log('_this.state',_this.state);
        _this.user$.next(_this.state);
    });
  }
  onPageChange(pagination){
    console.log('UserService onPageChange,pagination=',pagination);
    let nextState = Object.assign(this.state,pagination);
    this.state = nextState;
    console.log('UserService onPageChange,state=',this.state);
    this.list();
  }
  onSearch(queryKey){
    let nextState = {...this.state,  currentQueryKey: queryKey };
    this.state = nextState;
    this.list();
  }
  AddUser(user){
    let _this = this;
    this.httpService.post(AppHttpApiURL.user.add, null, { params: {...user} }).subscribe(function (data) {
        if (data.code > 0) {
            _this.messageService.open('添加成功！', 'success');
            _this.user$.next({ isAddSuccess: true });
        }
        else {
            _this.messageService.open(data.message, 'error');
        }
    });
  }
  ModifyUser(user){
    let _this=this;
    this.httpService.post(AppHttpApiURL.user.modifyremaintimes,null,{...user}).subscribe(data=>{
      _this.messageService.open('操作成功！', 'success');
      _this.user$.next({ isSuccess: true });
      _this.list();
    });
  }
  DisableUser(userid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要禁用该用户？',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.user.disable, null, { params: { userid: userid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.list();
        },
        active: true
    });
  }
  ActiveUser(userid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要启用该用户?',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.user.active, null, { params: { userid: userid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.list();
        },
        active: true
    });
  }
  UnLockUser(userid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要解锁该用户?',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.user.unlock, null, { params: { userid: userid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.list();
        },
        active: true
    });
  }
  DelUser(userid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要删除该用户?',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.user.del, null, { params: { userid: userid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.state.datacount = _this.state.datacount - 1;
            _this.list();
        },
        active: true
    });
  }
  ExportUser(type){
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end,
      status
    }=this.state;
    let params:any={
      keywords,pageindex,pagesize,orderby,
      ordertype,register_date_start,register_date_end,
      lastuse_date_start,lastuse_date_end,status
    }
    if(type==3){
      //导出历史所有
      params={pagesize:1000000};
    }else if(type==2){
      //导出所有页
      params = {
        keywords: keywords,
        pageindex: pageindex,
        pagesize: 100000,
        register_date_start: register_date_start,
        register_date_end: register_date_end,
        lastuse_date_start: lastuse_date_start,
        lastuse_date_end: lastuse_date_end,
        status: status
      };
    }
    this.fileDown.DownLoadFile(AppHttpApiURL.user.export,params);
  }
  
}
