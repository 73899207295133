import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimespan'
})
export class FormatTimespanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value)return '';
    let timeStr = '';
    let seconds = value;
    let hour = Math.floor(seconds / 3600);
    seconds = seconds - hour * 3600;
    let minute = Math.floor(seconds / 60);
    seconds = seconds - minute * 60;
    let second = Math.floor(seconds);
    if (!args) {
        return [hour.toString().padStart(2, '0'), minute.toString().padStart(2, '0'), second.toString().padStart(2, '0')].join(':');
    }
    else {
        args = args.replace('HH', hour.toString().padStart(2, '0'));
        args = args.replace('mm', minute.toString().padStart(2, '0'));
        args = args.replace('ss', second.toString().padStart(2, '0'));
        return args;
    }
  }

}
