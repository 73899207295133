import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SetService } from '../set.service';

@Component({
  selector: 'app-reset-time',
  templateUrl: './reset-time.component.html',
  styleUrls: ['./reset-time.component.css']
})
export class ResetTimeComponent implements OnInit {
  private subscription:Subscription;
  restTime:number;
  constructor(
    private route:ActivatedRoute,
    private setService:SetService
  ) { 
    let _this=this;
    this.subscription = this.setService.get().subscribe(function (data) {
      _this.restTime = data.resetTime;
  });
  }

  ngOnInit() {
    this.setService.getResetTime();
  }
  onSubmit(){
    this.setService.modifyResetTime(this.restTime);
  }

}
