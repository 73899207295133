import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class StorageService {
  private storage$:Subject<any>=new Subject<any>();
  private state={
    username:'未登录',
    token:''
  };
  constructor() { }
  get(){
    return this.storage$.asObservable();
  }
  getState(){
    return this.state;
  }
  update(state){
    this.state = Object.assign(this.state, state);
    localStorage.setItem('token', state.token);
    localStorage.setItem('username', state.username || '未登录');
    this.storage$.next(this.state);
  }
}
