import { Injectable } from '@angular/core';
import { HttpService } from '../shared/service/http.service';
import { MessageService } from '../shared/service/message.service';
import { ConfirmService } from '../shared/service/confirm.service';
import { Subject } from 'rxjs';
import AppHttpApiURL from 'src/config/app.url.config';

@Injectable()
export class SetService {
  private set$=new Subject<any>();
  state={
    limitTime: 0,
    limitVipTime: 0,
    lockTime: 0,
    resetTime: '',
    tips:{
      ID:0,
      TipType:0,
      TipContent:'',
      Status:''
    }
  };
  constructor(
    private httpService:HttpService,
    private messageService:MessageService,
    private confirmService:ConfirmService
  ) { }
  get(){
    return this.set$.asObservable();
  }
  getTimeLimit(){
    let _this = this;
    return this.httpService.get(AppHttpApiURL.set.limit.get).subscribe(function (res) {
        _this.state.limitTime = res.data;
        _this.set$.next(_this.state);
    });
  }
  getTimeLimitVIP(){
    let _this = this;
    return this.httpService.get(AppHttpApiURL.set.limitvip.get).subscribe(function (res) {
        _this.state.limitVipTime = res.data;
        _this.set$.next(_this.state);
    });
  }
  modifyLimit(minutes){
    let _this = this;
    this.httpService.post(AppHttpApiURL.set.limit.modify, null, { params: { minutes: minutes } }).subscribe(function (res) {
        _this.state.limitTime = minutes;
        _this.set$.next(_this.state);
    });
  }
  modifyLimitVIP(minutes){
    let _this = this;
    this.httpService.post(AppHttpApiURL.set.limitvip.modify, null, { params: { minutes: minutes } }).subscribe(function (res) {
        _this.state.limitVipTime = minutes;
        _this.set$.next(_this.state);
    });
  }
  getLockTime(){
    let _this = this;
    return this.httpService.get(AppHttpApiURL.set.lockTime.get).subscribe(function (res) {
        _this.state.lockTime = res.data;
        _this.set$.next(_this.state);
    });
  }
  modifyLockTime(lockTime){
    let _this = this;
    this.httpService.post(AppHttpApiURL.set.lockTime.modify, null, { params: { lockTime: lockTime } }).subscribe(function (res) {
        _this.state.lockTime = lockTime;
        _this.set$.next(_this.state);
    });
  }
  getResetTime(){
    let _this = this;
    return this.httpService.get(AppHttpApiURL.set.resetTime.get).subscribe(function (res) {
        console.log(res);
        _this.state.resetTime = res.data;
        _this.set$.next(_this.state);
    });
  }
  modifyResetTime(resetTime) {
      let _this = this;
      this.httpService.post(AppHttpApiURL.set.resetTime.modify, null, { params: { resetTime: resetTime } }).subscribe(function (res) {
          _this.state.resetTime = resetTime;
          _this.set$.next(_this.state);
      });
  }
  modifySuperPwd(obj) {
      let _this = this;
      let {oldvalue,newvalue} = obj;
      this.httpService.post(AppHttpApiURL.set.superPwd.modify, null, { params: { oldvalue: oldvalue, newvalue: newvalue } }).subscribe(function (res) {
          if (res.code > 0) {
              _this.messageService.open('修改成功');
          }
          else {
              _this.messageService.open(res.message, 'error');
          }
      });
  }
  getTips(){
    let _this=this;
    _this.httpService.get(AppHttpApiURL.set.tips.tips).subscribe(function(res){
      res.data.Status=res.data.Status==0;
      _this.state.tips=res.data;
      _this.set$.next(_this.state);
    }); 
  }
  modifyTips(tips){
    let _this = this;
    tips.Status=tips.Status?0:1;
    _this.httpService.post(AppHttpApiURL.set.tips.modify, null, { params: { 
      id:tips.ID,
      type:tips.TipType,
      content:tips.TipContent,
      status:tips.Status
    } }).subscribe(function (res) {
        if(res.code>0){
          _this.getTips();
          _this.messageService.open('操作成功');
        }
    });
  }

}
