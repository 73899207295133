import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluateComponent } from './evaluate/evaluate.component';
import { DetailComponent } from './detail/detail.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { EvaluateRoutingModule } from './evaluate-routing/evaluate-routing.module';

@NgModule({
  declarations: [EvaluateComponent, DetailComponent],
  imports: [
    CommonModule,FormsModule,SharedModule
  ],
  exports:[EvaluateComponent,DetailComponent,EvaluateRoutingModule]
})
export class EvaluateModule { }
