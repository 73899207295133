import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  evaluate:any={};
  behaviors:Array<string>=[];
  constructor(
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    var _this = this;
    this.route.queryParams.subscribe(function (params) {
        _this.evaluate = {...params};
        if(_this.evaluate.BadBehavior){
          _this.behaviors=_this.evaluate.BadBehavior.split('yangzhisplit');
        }
        console.log(_this.evaluate);
    });
  }

}
