import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }
  JsonToParas(params){
    let paramsArr = [];
    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            paramsArr.push(key + '=' + params[key]);
        }
    }
    return paramsArr.join('&');
  }
  ParseDateTime(value){
    if(!value)return null;
    let date = new Date(parseInt(value.substr(6)));
    if (date.getFullYear() <= 1900) {
        return null;
    }
    return date;
  }
}
