import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-btn',
  templateUrl: './dropdown-btn.component.html',
  styleUrls: ['./dropdown-btn.component.css']
})
export class DropdownBtnComponent implements OnInit {
  @Input() innerText:string;
  constructor() { }

  ngOnInit() {
  }

}
