import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/shared/service/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private subscription:Subscription;
  private username='';
  constructor(
    private storageService:StorageService
  ) { 
    this.subscription=this.storageService.get().subscribe(user=>{
      let username=user.username;
      this.username=username;
    })
  }

  ngOnInit() {
    this.username=this.storageService.getState().username;
  }

}
