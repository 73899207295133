import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RoomService } from '../room.service';
declare var laydate;
@Component({
  selector: 'app-room-vip',
  templateUrl: './room-vip.component.html',
  styleUrls: ['./room-vip.component.css']
})
export class RoomVipComponent implements OnInit {
  private subscription:Subscription
  room:any={};
  editing:boolean=false;
  user:any={
    employeeno:'',
    starttime:'',
    endtime:'',
    roomid:0
  };
  users=[];
  dataCount:number=0;
  pageIndex = 1;
  pageSize = 20;
  keyWords = '';
  suggestions=[];
  searchPlaceholder:string='工号/手机号';
  inteval:any;
  constructor(
    private route:ActivatedRoute,
    private roomService:RoomService
  ) { 
    let _this=this;
    this.subscription = this.roomService.getroomvip().subscribe(function (data) {
      let {
        users,
        suggestions,
        pageindex,
        pagesize,
        datacount
      }=data;
      _this.users = users;
      _this.pageIndex = pageindex;
      _this.dataCount = datacount;
      _this.pageSize = pagesize;
      _this.suggestions=suggestions;
    });
  }

  ngOnInit() {
    let _this=this;
    this.route.queryParams.subscribe(function (params) {
      _this.room=params;
      _this.user.roomid=params.ID;
      _this.roomService.roomuserlist(params.ID);
    });
    this.bindLayDate();
  }
  onSearchChange(searchKeyword){
    console.log('onSearchChange,searchKeyword=',searchKeyword);
    this.keyWords = searchKeyword;
    this.roomService.onUserPageChange({ keywords: searchKeyword, pageindex: 1 });
  }
  onPageChange(currentPage){
    this.roomService.onUserPageChange({ pageindex: currentPage });
  }
  onDateInputChange(key, value){
    this.user[key] = value;
  }
  onInputChange(value){
    let _this=this;
    clearTimeout(_this.inteval);
    _this.inteval=setTimeout(()=>{
      _this.roomService.roomvipusersuggestions(value);
    },1000);
    
  }
  onAddUserClick(){
    this.editing=true;
  }
  onSubmitUser(){
    this.roomService.AddRoomUser(this.user);
    this.user.employeeno='';
    this.user.starttime='';
    this.user.endtime='';
  }
  onCancelUser(){
    this.editing=false;
  }
  onDelUser(userid){
    let _this=this;
    this.roomService.DelRoomUser(_this.room.ID,userid);
  }
  bindLayDate(){
    let that = this;
    laydate.render({
        elem: '#startTime',
        done: function (value, date, endDate) {
            that.user.starttime = value;
        }
    });
    laydate.render({
        elem: '#endTime',
        done: function (value, date, endDate) {
          that.user.endtime = value;
        }
    });
  }

}
