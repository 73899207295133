import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/shared/service/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private user={
    username:'',
    password:''
  };
  private subscription:Subscription
  constructor(
    private accountService:AccountService,
    private router:Router,
    private storageService:StorageService
  ) { 

    this.subscription=this.accountService.get().subscribe(res=>{
      if(res.isSuccess){
        this.router.navigate(['/welcome']);
      }
    });
  }

  ngOnInit() {
    this.init();
  }
  init(){
    this.changeImgCode();
  }
  changeImgCode(){
    //this.imgCodeUrl=this.accountService.getImgCodeUrl();
  }
  onSubmit(){
    this.accountService.login(this.user);
  }


}
