import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import appSettings from 'src/config/appsetting.config';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(
    private util:UtilService
  ) { }
  DownLoadFile(url,params){
    window.open(location.origin+'/api'+url+"?"+this.util.JsonToParas(Object.assign(params,{id:localStorage.getItem('token')})));
  }
}
