import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Tab {
  id: number;
  name: string;
  link: string;
  active:boolean;
  outlet: string;
}

const homeTab = {
  id: 1,
  name: '登陆',
  link: '/account/login',
  active:true,
  outlet: 'login'
};

@Injectable()
export class TabsService {
  private tabs$ = new Subject<any>();
  id = 1;
  tabs: Tab[] = [homeTab];
  curBreadCrumb = {
    name: '首页',
    link: 'home',
    icon: 'home',
    parentBreadCrumb: null
  };
  menus = [
    {
        name: '员工管理',
        icon: 'person',
        isOpenSub: false,
        subMenu: [
            {
                name: '员工列表',
                link: '/user'
            },
            {
                name: '添加员工',
                link: '/user/add'
            },
            {
                name: '修改员工信息',
                link: '/user/modify'
            }
        ]
    },
    {
        name: '房间管理',
        icon: 'store',
        isOpenSub: false,
        subMenu: [
            {
                name: '房间列表',
                link: '/room'
            },
            {
                name: '添加房间',
                link: '/room/add'
            },
            {
                name: '修改房间信息',
                link: '/room/modify'
            },
            {
              name: '房间用户管理',
              link: '/room/vip'
          }
        ]
    },
    {
      name: '使用记录',
      icon: 'materials',
      link: '/record/records'
    },
    {
      name: '摄像头记录',
      icon: 'materials',
      isOpenSub: false,
        subMenu: [
            {
                name: '捕捉记录日志',
                link: '/camera/log'
            },
            {
                name: '进出门记录',
                link: '/camera/pass'
            }
        ]
    },
    {
        name: '奖品管理',
        icon: 'jiangpin',
        isOpenSub: false,
        subMenu: [
            {
                name: '奖品列表',
                link: '/prize'
            },
            {
                name: '添加奖品',
                link: '/prize/add'
            }
        ]
      },
      {
        name: '评价管理',
        icon: 'evaluate',
        isOpenSub: false,
        subMenu: [
            {
                name: '评价列表',
                link: '/evaluate'
            }
        ]
      },
      {
        name: '留言板',
        icon: 'evaluate',
        isOpenSub: false,
        subMenu: [
            {
                name: '留言列表',
                link: '/guestbook'
            }
        ]
      },
    {
        name: '系统配置',
        icon: 'set',
        isOpenSub: false,
        subMenu: [
          {
            name:'首页提示',
            link:'/set/tips'
          },
          {
              name: '每日限时',
              link: '/set/limit'
          },
          {
            name: '每日限时(专属房间用户)',
            link: '/set/limitvip'
          },
          {
              name: '每日重置时间',
              link: '/set/resettime'
          },
          {
              name: '违规锁定时长',
              link: '/set/locktime'
          },
          {
              name: '超级密码',
              link: '/set/superpwd'
          }
        ]
    },
    {
        name: '统计',
        icon: 'statistics',
        link: '/statistics'
    }
  ];
  constructor() { }
  all(){
    return this.curBreadCrumb;
  }
  get(){
    return this.tabs$.asObservable();
  }
  create(tab){
    this.tabs.push({
      id: ++this.id,
      ...tab
    });
    this.tabs$.next(this.tabs);
  }
  remove(tab: Tab) {
    this.tabs = this.tabs.filter(item => item.id !== tab.id);
    this.change(this.tabs[this.tabs.length-1]);
  }
  change(tab){
    this.tabs.map(item=>{
      return item.active=item.id==tab.id;
    });
    this.tabs$.next(this.tabs);
  }
  changeBreadcrumb(name){
    let result;
    for (let _i = 0, _a = this.menus; _i < _a.length; _i++) {
      let menu = _a[_i];
        if (menu.name == name) {
            result = {
                name: menu.name,
                link: menu.link,
                icon: menu.icon,
                parentBreadCrumb: null
            };
        }
        else if (menu.subMenu && menu.subMenu.length > 0) {
            for (let _b = 0, _c = menu.subMenu; _b < _c.length; _b++) {
              let menuSub = _c[_b];
              if (menuSub.name == name) {
                  result = {
                      name: menuSub.name,
                      link: menuSub.link,
                      icon: '',
                      parentBreadCrumb: {
                          name: menu.name,
                          icon: menu.icon,
                      }
                  };
              }
            }
        }
    }
    this.curBreadCrumb = result;
    this.tabs$.next(result);
  }
  clear() {
    this.id = 1;
    this.tabs = [homeTab];
    this.tabs$.next(this.tabs);
  }
}
