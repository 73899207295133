import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrizeComponent } from './prize/prize.component';
import { PrizeAddComponent } from './prize-add/prize-add.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PrizeRoutingModule } from './prize-routing/prize-routing.module';

@NgModule({
  declarations: [PrizeComponent, PrizeAddComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  exports:[PrizeComponent,PrizeAddComponent,PrizeRoutingModule]
})
export class PrizeModule { }
