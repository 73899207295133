import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared/service/message.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
  private subscription:Subscription;
  user={
    username:'',
    department:'',
    employee_no:''
  };
  constructor(
    private userService:UserService,
    private messageService:MessageService
  ) { 
    let _this=this;
    this.subscription = this.userService.get().subscribe(function (data) {
      if (data.isSuccess) {
        for (const key in _this.user) {
          if (Object.hasOwnProperty(key)) {
            _this.user[key] = ''
          }
        }
      }
    });
  }

  ngOnInit() {
  }
  onSubmit(){
    let _this=this;
    let error=false;
    for (const key in _this.user) {
      if (Object.hasOwnProperty(key)) {
        if(_this.user[key]==''){
          error=true;
        }
      }
    }
    if (error) {
      this.messageService.open('信息不完整，请确认！', 'error');
      return;
    }
    this.userService.AddUser({..._this.user,realname: this.user.username});
  }
  
}
