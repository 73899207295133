import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuestBookService } from '../guestbook.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  private subscription:Subscription;
  id:Number = 0;
  detail:any={};
  formData:any={};
  behaviors:Array<string>=[];
  constructor(
    private guestbookService:GuestBookService,
    private route:ActivatedRoute
  ) { 
    let _this=this;
    this.subscription = this.guestbookService.get().subscribe(function (data) {
      let {
        detail,
      }=data;
      _this.detail = detail;
      _this.formData = {
        ParentId:detail.ID,
        MsgContent:'',
      }
    });

  }

  ngOnInit() {
    var _this = this;
    this.route.queryParams.subscribe(function (params) {
        _this.detail = {...params};
        _this.id = params.id;
        _this.getDetail();
    });
  }
  onInputChange(key, value){
    console.log('onInputChange',key, value);
    this.formData[key] = value;
  }
  onSubmit(){
    var _this = this;
    console.log('onSubmit',this.formData);
    if(!this.formData.MsgContent){
      _this.guestbookService.messageService.open('请输入内容！', 'error');
      return;
    }
    this.guestbookService.reply(this.formData);
  }
  getDetail(){
    let _this = this;
    this.guestbookService.detail(this.id);
  }
}
