import { Component, OnInit } from '@angular/core';
import { SetService } from '../set.service';

@Component({
  selector: 'app-superpwd',
  templateUrl: './superpwd.component.html',
  styleUrls: ['./superpwd.component.css']
})
export class SuperpwdComponent implements OnInit {
  oldvalue:string;
  newvalue:string;
  constructor(
    private setService:SetService
  ) { }

  ngOnInit() {
  }
  onSubmit(){
    this.setService.modifySuperPwd({
      oldvalue: this.oldvalue,
      newvalue: this.newvalue
    });
  }

}
