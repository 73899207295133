import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordComponent } from '../record/record.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path: 'record',
      component: RecordComponent
  },
  {
      path: 'record/records',
      component: RecordComponent
  },
  {
      path: 'record/records/:keywords',
      component: RecordComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ],
  declarations: []
})
export class RecordRoutingModule { }
