const AppHttpApiURL = {
    account: {
        login: '/account/login'
    },
    user: {
        users: '/user/users',
        add: '/user/add',
        modify: '/user/modify',
        modifyremaintimes: '/user/modifyuserremains',
        disable: '/user/disable',
        active: '/user/active',
        unlock: '/user/unlock',
        del: '/user/del',
        user: '/user/user',
        export: '/export/users'
    },
    record: {
        records: '/record/records',
        add: '/record/add',
        export: '/export/records',
        export_history:'/export/records_history',
        update_status:'/record/UpdateRecordStatus'
    },
    evaluate:{
        evaluates:'/evaluate/evaluates',
        detail:'/evaluate/detail',
        export: '/export/evaluates'
    },
    guestbook:{
        list:'/guestbook/list',
        detail:'/guestbook/detail',
        add:'/guestbook/add',
    },
    prize:{
        prizes:'/prize/prizes',
        add:'/prize/add',
        del:'/prize/del',
        statistics:'/prize/statistics',
        export:'/export/prizes',
        export_all:'/export/prizes_all'
    },
    room: {
        rooms: '/room/list',
        add: '/room/add',
        modify: '/room/modify',
        disable: '/room/disable',
        active: '/room/active',
        del: '/room/del',
        setvip:'/room/setvip',
        cancelvip:'/room/cancelvip',
        vipusers:'/room/users',
        addvipuser:'/room/addvipuser',
        delvipuser:'/room/delvipuser'
    },
    camera:{
        logs:'/camera/list',
        pass:'/camera/passlist',
        export: '/export/camera/log'
    },
    set: {
        limit: {
            get: '/set/limit',
            modify: '/set/limit/modify'
        },
        limitvip:{
            get: '/set/limit/vip',
            modify: '/set/limit/vip/modify'
        },
        lockTime: {
            get: '/set/locktime',
            modify: '/set/locktime/modify'
        },
        resetTime: {
            get: '/set/resettime',
            modify: '/set/resettime/modify'
        },
        superPwd: {
            modify: '/set/superpassword/modify'
        },
        tips:{
            modify:'/tips/modify',
            tips:'/tips/tips'
        },
    }
};
export default AppHttpApiURL;