import { Injectable } from '@angular/core';
import { HttpService } from '../shared/service/http.service';
import { MessageService } from '../shared/service/message.service';
import { Subject } from 'rxjs';
import { ConfirmService } from '../shared/service/confirm.service';
import AppHttpApiURL from 'src/config/app.url.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomService {
  private room$=new Subject<any>();
  private roomvip$=new Subject<any>();
  state={
    rooms: [],
    orderby: '',
    ordertype: 0,
    keywords: '',
    pageindex: 1,
    pagesize: 20,
    datacount: 0
  };
  roomUserState={
    users:[],
    suggestions:[],
    roomid:0,
    keywords:'',
    pageindex:1,
    pagesize:20,
    datacount:0
  };
  constructor(
    private httpService:HttpService,
    private http:HttpClient,
    private messageService:MessageService,
    private confirmService:ConfirmService
  ) { 

  }
  get(){
    return this.room$.asObservable();
  }
  getroomvip(){
    return this.roomvip$.asObservable();
  }
  list(){
    console.log('room.service list');
    let {keywords,pageindex,pagesize}=this.state;
    return this.httpService.get(AppHttpApiURL.room.rooms,{
      keywords,pageindex,pagesize
    }).subscribe(data=>{
      let nextState={
        ...this.state,
        rooms:data.data,
        datacount:data.code
      };
      this.state=Object.assign(this.state,nextState);
      console.log('room.service list,this.state=',this.state);
      this.room$.next(this.state);
    });
  }
  setroomid(roomid){
    this.roomUserState.roomid=roomid;
  }
  roomuserlist(id?:number){
    console.log('roomuserlist,id=',id);
    this.roomUserState.roomid=id||this.roomUserState.roomid;
    let {keywords,pageindex,pagesize,roomid}=this.roomUserState;
    return this.httpService.get(AppHttpApiURL.room.vipusers,{
      keywords,pageindex,pagesize,roomid
    }).subscribe(data=>{
      let nextState={
        ...this.roomUserState,
        users:data.data,
        datacount:data.code
      };
      this.roomUserState=Object.assign(this.roomUserState,nextState);
      this.roomvip$.next(this.roomUserState);
    });
  }
  roomvipusersuggestions(keywords){
    return this.httpService.get(AppHttpApiURL.user.users,{
      keywords: keywords,
      pageindex: 1,
      pagesize: 10,
    }).subscribe(data=>{
      let nextState={
        ...this.roomUserState,
        suggestions:data.data
      };
      this.roomUserState=Object.assign(this.roomUserState,nextState);
      this.roomvip$.next(this.roomUserState);
    });
  }
  onPageChange(pagination){
    let nextState = Object.assign(this.state,pagination);
    this.state = nextState;
    this.list();
  }
  onUserPageChange(pagination){
    console.log('onUserPageChange,pagination=',pagination);
    let nextState = Object.assign(this.roomUserState,pagination);
    this.roomUserState = nextState;
    this.roomuserlist();
  }
  onSearch(queryKey){
    let nextState = {...this.state,  currentQueryKey: queryKey };
    this.state = nextState;
    this.list();
  }
  AddRoom(room){
    let _this = this;
    this.httpService.post(AppHttpApiURL.room.add, null, { params: {...room} }).subscribe(function (data) {
        if (data.code > 0) {
            _this.messageService.open('添加成功！', 'success');
            _this.room$.next({ isSuccess: true });
        }
        else {
            _this.messageService.open(data.message, 'error');
        }
    });
  }
  ModifyRoom(room){
    let _this = this;
    this.httpService.post(AppHttpApiURL.room.modify, null, { params: {...room} }).subscribe(function (data) {
        if (data.code > 0) {
            _this.messageService.open('操作成功！', 'success');
            _this.room$.next({ isSuccess: true });
            _this.list();
        }
        else {
            _this.messageService.open(data.message, 'error');
        }
    });
  }
  DisableRoom(roomid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要禁用该房间？',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.room.disable, null, { params: { roomid: roomid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.list();
        },
        active: true
    });
  }
  ActiveRoom(roomid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要启用该房间？',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.room.active, null, { params: { roomid: roomid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.list();
        },
        active: true
    });
  }
  DelRoom(roomid){
    let _this = this;
    this.confirmService.open({
        content: '您确认要删除该房间？',
        operate: function () {
            _this.httpService.post(AppHttpApiURL.room.del, null, { params: { roomid: roomid } }).subscribe(function (data) {
                _this.messageService.open('操作成功！', 'success');
            });
            _this.state.datacount = _this.state.datacount - 1;
            _this.list();
        },
        active: true
    });
  }
  SetRoomAsVip(roomid){
    let _this=this;
    this.confirmService.open({
      content: '您确认要将该房间设为专用？',
      operate: function () {
          _this.httpService.post(AppHttpApiURL.room.setvip, null, { params: { roomid: roomid } }).subscribe(function (data) {
              _this.messageService.open('操作成功！', 'success');
              _this.list();
          });
      },
      active: true
    });
  }
  CancelRoomAsVip(roomid){
    let _this=this;
    this.confirmService.open({
      content: '您确认要将该房间设为公用？',
      operate: function () {
          _this.httpService.post(AppHttpApiURL.room.cancelvip, null, { params: { roomid: roomid } }).subscribe(function (data) {
              _this.messageService.open('操作成功！', 'success');
              _this.list();
          });
      },
      active: true
    });
  }
  AddRoomUser(user){
    let _this=this;
    if(user.employeeno==''||user.starttime==''||user.endtime==''){
      _this.messageService.open('参数不完整，请检查', 'error');
      return;
    }
    this.httpService.post(AppHttpApiURL.room.addvipuser, null, { params: {
      employeeno:user.employeeno,
      starttime:user.starttime,
      endtime:user.endtime,
      roomid:user.roomid
    } }).subscribe(function (data) {
      if (data.code > 0) {
          _this.messageService.open('添加成功！', 'success');
      }
      else {
          _this.messageService.open(data.message, 'error');
      }
      _this.roomuserlist();
    });
  }
  DelRoomUser(roomid,userid){
    let _this = this;
    _this.httpService.post(AppHttpApiURL.room.delvipuser, null, { params: { roomid: roomid,userid:userid } }).subscribe(function (data) {
      _this.messageService.open('操作成功！', 'success');
      _this.roomuserlist();
    });
  }
}
