import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { RouteOutletDirective } from './shared/directive/route-outlet.directive';
import { SharedModule } from './shared/shared.module';
import { AccountModule } from './account/account.module';
import { RecordModule } from './record/record.module';
import { RoomModule } from './room/room.module';
import { SetModule } from './set/set.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { TabsService } from './home/tabs/tabs.service';
import { MessageService } from './shared/service/message.service';
import { HttpService } from './shared/service/http.service';
import { MyHttpInterceptor } from './shared/class/http-interceptor';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HomeModule } from './home/home.module';
import { ConfirmService } from './shared/service/confirm.service';
import { LoadingService } from './shared/service/loading.service';
import { StorageService } from './shared/service/storage.service';
import { UserService } from './user/user.service';
import { RoomService } from './room/room.service';
import { RecordService } from './record/record.service';
import { SetService } from './set/set.service';
import { UserModule } from './user/user.module';
import { UtilService } from './shared/service/util.service';
import { PrizeModule } from './prize/prize.module';
import { EvaluateModule } from './evaluate/evaluate.module';
import { EvaluateService } from './evaluate/evaluate.service';
import { GuestBookModule } from './guestbook/guestbook.module';
import { GuestBookService } from './guestbook/guestbook.service';
import { PrizeService } from './prize/prize.service';
import { CamceraModule } from './camcera/camcera.module';
import { CamceraService } from './camcera/camcera.service';
import { CameraPassService } from './camcera/camera-pass.service';

@NgModule({
  declarations: [
    AppComponent,
    RouteOutletDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HomeModule,
    SharedModule,
    AccountModule,
    RecordModule,
    RoomModule,
    SetModule,
    UserModule,
    PrizeModule,
    EvaluateModule,
    GuestBookModule,
    // CamceraModule,
    AppRoutingModule,
    HttpClientModule
    
  ],
  providers: [
    TabsService,
    MessageService,
    ConfirmService,
    LoadingService,
    StorageService,
    UtilService,
    UserService,
    RoomService,
    RecordService,
    SetService,
    EvaluateService,
    GuestBookService,
    CamceraService,
    CameraPassService,
    PrizeService,
    HttpService,
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi:
      true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
