import { Component, OnInit } from '@angular/core';
import { PrizeService } from '../prize.service';
import { MessageService } from 'src/app/shared/service/message.service';
import { Subscription } from 'rxjs';
declare var laydate;
@Component({
  selector: 'app-prize-add',
  templateUrl: './prize-add.component.html',
  styleUrls: ['./prize-add.component.css']
})
export class PrizeAddComponent implements OnInit {

  private subscription:Subscription;
  prize={
    prize_name:'',
    prize_desc:'',
    prize_value:undefined,
    prize_effective_time_start:undefined,
    prize_effective_time_end:undefined,
    prize_use_effective_time:undefined,
    prize_count:''
  };
  constructor(
    private prizeService:PrizeService,
    private messageService:MessageService
  ) { 
    let _this=this;
    this.subscription = this.prizeService.get().subscribe(function (data) {
      if (data.isAddSuccess) {
        console.log('data.isAddSuccess',data.isAddSuccess);
        for (let key in _this.prize) {
          console.log('key',key);
          if (_this.prize.hasOwnProperty(key)) {
            _this.prize[key] = ''
            console.log(_this.prize[key]);
          }
        }
      }
    });
  }

  ngOnInit() {
    this.bindLayDate();
  }
  onSubmit(){
    let _this=this;
    let error=false;
    for (const key in _this.prize) {
      if (Object.hasOwnProperty(key)) {
        if(_this.prize[key]==''&&key.toLowerCase()!='prize_desc'){
          error=true;
        }
      }
    }
    if (error) {
      this.messageService.open('信息不完整，请确认！', 'error');
      return;
    }
    this.prizeService.AddPrize({..._this.prize});
  }
  bindLayDate(){
    let that = this;
    laydate.render({
        elem: '#prize_effective_time_start',
        done: function (value, date, endDate) {
            that.prize.prize_effective_time_start=value;
        }
    });
    laydate.render({
        elem: '#prize_effective_time_end',
        done: function (value, date, endDate) {
            that.prize.prize_effective_time_end=value;
        }
    });
    laydate.render({
        elem: '#prize_use_effective_time',
        done: function (value, date, endDate) {
            that.prize.prize_use_effective_time=value;
        }
    });
  }

}
