import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAddComponent } from './user-add/user-add.component';
import { UserModifyComponent } from './user-modify/user-modify.component';
import { FormsModule } from '@angular/forms';
import { UserComponent } from './user.component';
import { SharedModule } from '../shared/shared.module';
import { UserService } from './user.service';
import { UserRoutingModule } from './user-routing/user-routing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [UserAddComponent, UserModifyComponent,UserComponent],
  exports:[UserComponent,UserAddComponent,UserModifyComponent,UserRoutingModule]
})
export class UserModule { }
