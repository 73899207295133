import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './component/pagination/pagination.component';
import { ConfirmComponent } from './component/confirm/confirm.component';
import { MessageComponent } from './component/message/message.component';
import { SearchComponent } from './component/search/search.component';
import { FormatDatetimePipe } from './pipe/format-datetime.pipe';
import { LoadingComponent } from './component/loading/loading.component';
import { FormatTimespanPipe } from './pipe/format-timespan.pipe';
import { DropdownBtnComponent } from './component/dropdown-btn/dropdown-btn.component';
import { DropdownBtnItemComponent } from './component/dropdown-btn/dropdown-btn-item/dropdown-btn-item.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PaginationComponent,
    ConfirmComponent,
    MessageComponent,
    SearchComponent,
    LoadingComponent,
    FormatDatetimePipe,
    FormatTimespanPipe,
    DropdownBtnComponent,
    DropdownBtnItemComponent
  ],
  exports:[
    PaginationComponent,
    ConfirmComponent,
    MessageComponent,
    LoadingComponent,
    SearchComponent,
    DropdownBtnComponent,
    DropdownBtnItemComponent,
    FormatDatetimePipe,
    FormatTimespanPipe
  ]
})
export class SharedModule { }
