import { Directive } from '@angular/core';

@Directive({
  selector: '[appRouteOutlet]'
})
export class RouteOutletDirective {

  constructor(
  ) { }

}
