import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from '../shared/service/http.service';
import { MessageService } from '../shared/service/message.service';
import { ConfirmService } from '../shared/service/confirm.service';
import { FileDownloadService } from '../shared/service/file-download.service';
import AppHttpApiURL from 'src/config/app.url.config';

@Injectable({
  providedIn: 'root'
})
export class EvaluateService {
  private evaluates$:Subject<any>=new Subject();
  state={
    evaluates:[],
    orderby:'',
    ordertype: 0,
    keywords: '',
    pageindex: 1,
    pagesize: 15,
    datacount: 0,
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  constructor(
    private httpService:HttpService,
    private messageService:MessageService,
    private confirmService:ConfirmService,
    private fileDown:FileDownloadService
  ) { }
  get(){
    return this.evaluates$.asObservable();
  }
  list(){
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end
    }=this.state;
    return this.httpService.get(AppHttpApiURL.evaluate.evaluates,{
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end
    }).subscribe(data=>{
      let nextState={
        ...this.state,
        evaluates:data.data,
        datacount:data.code
      };
      this.state=Object.assign(this.state,nextState);
      this.evaluates$.next(this.state);
    })
  }
  onPageChange(pagination){
    let nextState=Object.assign(this.state,pagination);
    this.state = nextState;
    this.list();
  }
  onSearch(queryKey){
    let nextState={
      ...this.state,
      currentQueryKey: queryKey
    };
    this.state = nextState;
    this.list();
  }
  ExportEvaluate(type){
    let {
      keywords,
      pageindex,
      pagesize,
      orderby,
      ordertype,
      register_date_start,
      register_date_end,
      lastuse_date_start,
      lastuse_date_end,
    }=this.state;
    let params:any={
      keywords,pageindex,pagesize,orderby,
      ordertype,register_date_start,register_date_end,
      lastuse_date_start,lastuse_date_end
    }
    if(type==3){
      //导出历史所有
      params={pagesize:100000000};
    }else if(type==2){
      //导出所有页
      params = {
        keywords: keywords,
        pageindex: pageindex,
        pagesize: 10000000,
        register_date_start: register_date_start,
        register_date_end: register_date_end,
        lastuse_date_start: lastuse_date_start,
        lastuse_date_end: lastuse_date_end,
      };
    }
    this.fileDown.DownLoadFile(AppHttpApiURL.evaluate.export,params);
  }
}
