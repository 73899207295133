import { LoadingService } from "../service/loading.service";
import { MessageService } from "../service/message.service";
import { ConfirmService } from "../service/confirm.service";
import { Router } from "@angular/router";
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpEvent,HttpInterceptor,HttpHandler,HttpRequest,HttpResponse,HttpHeaders} from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor(
        private loading:LoadingService,
        private messageService:MessageService,
        private confirmService:ConfirmService,
        private router:Router
    ){}
    intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
        let _this=this;
        this.loading.open();
        console.log('MyInterceptor');
        const authReq = req.clone({
            headers:new HttpHeaders({'Authorization':localStorage.getItem('token') ? localStorage.getItem('token') : ''})
        });
     
        return next.handle(authReq).pipe(mergeMap((event:any) => {
            _this.loading.close();
            if (event && event.body && event.body.code == -9999) {
                _this.loading.close();
                _this.confirmService.open({
                    content: event.body.message,
                    operate: function () {
                        _this.router.navigate(['/account/login']);
                    },
                    active: true
                });
            }
            else {
              return of(event);
            }
          }),
          catchError((res: HttpResponse<any>) => {   //请求失败处理
            switch (res.status) {
              case 401:
                break;
              case 200:
                console.log('业务错误',res);
                break;
              case 404:
                break;
              case 403:
                console.log('业务错误',res);
                break;
            }
            _this.loading.close();
            return _this.handleData(res);
          }));
      }
      handleData(event){
          // 业务处理：一些通用操作
        switch (event.status) {
            case 200:
                if (event instanceof HttpResponse) {
                    var body = event.body;
                    console.log('body:', body);
                    if (body && body.rc == 3) {
                        //this.goTo('/test');
                        return Observable.create(observer => observer.next(event)); //请求成功返回响应
                    }
                }
                break;
            case 400:
                this.messageService.open('非法请求', 'error');
                break;
            case 404:
            case 500:
                this.messageService.open('系统繁忙，请稍后再试！', 'error');
                break;
            default:
                this.messageService.open('系统繁忙，请稍后再试！', 'error');
                return Observable.create(observer => observer.next(event)); //请求成功返回响应
        }
      }
    
}
