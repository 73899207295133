import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Message{
  content:string;
  type:string;
  active:boolean
}

@Injectable()
export class MessageService {
  private message$:Subject<any>=new Subject<any>();
  timeout:any;
  msg:Message;
  constructor() { }
  open(content, type:string='success'){
    var _this = this;
    if (type === void 0) { type = 'success'; }
    clearTimeout(this.timeout);
    this.msg = {
        content: content,
        type: type,
        active: true
    };
    this.message$.next(this.msg);
    this.timeout = setTimeout(function () {
        _this.close();
    }, 3000);
  }
  close(){
    this.msg = { content: '', type: '', active: false };
    this.message$.next(this.msg);
  }
  get(){
    return this.message$.asObservable();
  }
}
