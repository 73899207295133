import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrizeService } from '../prize.service';
import { Router } from '@angular/router';
import { TabsService } from 'src/app/home/tabs/tabs.service';

declare var laydate;
@Component({
  selector: 'app-prize',
  templateUrl: './prize.component.html',
  styleUrls: ['./prize.component.css']
})
export class PrizeComponent implements OnInit {

  private subscription:Subscription;
  pageIndex = 1;
  pageSize = 15;
  dataCount = 0;
  keyWords = '';
  orderby = '';
  ordertype = 0;
  status = -1;
  filterDate={
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  searchPlaceholder = '奖品名称';
  prizeList:any=[];
  statistics:any=[];
  constructor(
    private prizeService:PrizeService,
    private router:Router,
    private tabService:TabsService,
  ) { 
    let _this=this;
    this.subscription = this.prizeService.get().subscribe(function (data) {
      let {
        prizes,
        statistics,
        pageindex,
        pagesize,
        datacount,
        register_date_start,
        register_date_end,
        lastuse_date_start,
        lastuse_date_end
      }=data;
      _this.prizeList = prizes;
      _this.pageIndex = pageindex;
      _this.dataCount = datacount;
      _this.pageSize = pagesize;
      _this.statistics=statistics;
      _this.filterDate={
        register_date_start:register_date_start,
        register_date_end:register_date_end,
        lastuse_date_start:lastuse_date_start,
        lastuse_date_end:lastuse_date_end
      };
    });
  }

  ngOnInit() {
    this.prizeService.list();
    this.prizeService.statistics();
    this.bindLayDate();
  }
  onOrderChange(orderFiled){
    this.orderby = orderFiled;
    this.ordertype = this.ordertype > 0 ? 0 : 1;
    this.prizeService.onPageChange({ orderby: this.orderby, ordertype: this.ordertype });
  }
  onDateInputChange(key, value){
    this.filterDate[key] = value;
  }
  onSearchSubmit(){
    this.pageIndex=1;
    this.prizeService.onPageChange({
      ...this.filterDate, 
      keywords: this.keyWords,
      pageindex: 1, 
      pagesize: this.pageSize,
      status: this.status 
      });
  }
  onSearchChange(searchKeyword){
    this.keyWords = searchKeyword;
    this.prizeService.onPageChange({ keywords: searchKeyword, pageindex: 1 });
  }
  onPageChange(currentPage){
    this.prizeService.onPageChange({ pageindex: currentPage });
  }
  onDelPrize(groupno){
    this.prizeService.DelPrize(groupno);
  }
  onAddPrizeClick(){
    this.router.navigate(['/prize/add']);
    this.tabService.changeBreadcrumb('添加奖品');
  }
  onExport(type){
    this.prizeService.ExportPrize(type);
  }
  bindLayDate(){
    let that = this;
    laydate.render({
        elem: '#registerDateStart',
        done: function (value, date, endDate) {
            that.filterDate.register_date_start = value;
        }
    });
    laydate.render({
        elem: '#registerDateEnd',
        done: function (value, date, endDate) {
            that.filterDate.register_date_end = value;
        }
    });
    laydate.render({
        elem: '#LastUseDateStart',
        done: function (value, date, endDate) {
            that.filterDate.lastuse_date_start = value;
        }
    });
    laydate.render({
        elem: '#LastUseDateEnd',
        done: function (value, date, endDate) {
            that.filterDate.lastuse_date_end = value;
        }
    });
  }

}
