import { Component, OnInit } from '@angular/core';
import { RecordService } from '../record.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsService } from 'src/app/home/tabs/tabs.service';
import { Subscription } from 'rxjs';
declare var laydate;
@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css']
})
export class RecordComponent implements OnInit {
  private subscription:Subscription;
  private pageIndex=1;
  private pageSize=15;
  private dataCount;
  private keyWords;
  private orderby;
  private ordertype=0;
  private filterDate={
    register_date_start: '',
    register_date_end: '',
    lastuse_date_start: '',
    lastuse_date_end: '',
  };
  private searchPlaceholder = '用户工号/房间名';;
  private recordList;
  constructor(
    private recordService:RecordService,
    private router:Router,
    private route:ActivatedRoute,
    private tabService:TabsService
  ) { 
    this.subscription=this.recordService.get().subscribe(obj=>{
      var {records,pageindex,datacount,pagesize}=obj;
      this.recordList=records
      this.pageIndex=pageindex;
      this.dataCount=datacount;
      this.pageSize=pagesize;
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params=>{
      this.keyWords=params.keywords||'';
      this.recordService.onPageChange({
        keywords:this.keyWords
      });
    });
    this.bindLayDate();
  }
  onOrderChange(orderFiled){
    this.orderby = orderFiled;
    this.ordertype = this.ordertype > 0 ? 0 : 1;
    this.recordService.onPageChange({ orderby: this.orderby, ordertype: this.ordertype });
  }
  onSearchSubmit(){
    this.pageIndex=1;
    this.recordService.onPageChange(Object.assign({}, this.filterDate, { keywords: this.keyWords, pageindex: this.pageIndex, pagesize: this.pageSize }));
  }
  onDateInputChange(key, value){
    this.filterDate[key] = value;
  }
  onSearchChange(searchKeyword){
    this.keyWords = searchKeyword;
  }
  onPageChange(currentPage){
    this.recordService.onPageChange({ pageindex: currentPage });
  }
  onExport(type){
    this.recordService.ExportUser(type);
  }
  onResetRecordStatus(id,status){
    this.recordService.onResetRecordStatus(id,status);
  }
  bindLayDate(){
    let that = this;
    laydate.render({
        elem: '#registerDateStart',
        done: function (value, date, endDate) {
            that.filterDate.register_date_start = value;
        }
    });
    laydate.render({
        elem: '#registerDateEnd',
        done: function (value, date, endDate) {
            that.filterDate.register_date_end = value;
        }
    });
    laydate.render({
        elem: '#LastUseDateStart',
        done: function (value, date, endDate) {
            that.filterDate.lastuse_date_start = value;
        }
    });
    laydate.render({
        elem: '#LastUseDateEnd',
        done: function (value, date, endDate) {
            that.filterDate.lastuse_date_end = value;
        }
    });
  }
}
