import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SetService } from '../set.service';
import { MessageService } from 'src/app/shared/service/message.service';
import { ConfirmService } from 'src/app/shared/service/confirm.service';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
export class TipsComponent implements OnInit {

  private subscription:Subscription;
  hours:number;
  tips={
    TipType:'0',
    TipContent:'',
    Status:0
  };
  constructor(
    private route:ActivatedRoute,
    private setService:SetService,
    private messageService:MessageService,
    private confirmService:ConfirmService
    
  ) {
    let _this=this;
    this.subscription = this.setService.get().subscribe(function (data) {
      _this.tips = data.tips;
      _this.tips.TipType=_this.tips.TipType.toString();
    });
   }

  ngOnInit() {
    this.setService.getTips();
  }
  onPreview(){
    let _this=this; 
    this.confirmService.open({
      content:_this.tips.TipContent,
      active:true
    });
  }
  onSubmit(){
    console.log('tips=',this.tips);
    this.setService.modifyTips(this.tips);
  }

}
