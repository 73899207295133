import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomAddComponent } from './room-add/room-add.component';
import { RoomModifyComponent } from './room-modify/room-modify.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RoomRoutingModule } from './room-routing/room-routing.module';
import { RoomComponent } from './room.component';
import { RoomVipComponent } from './room-vip/room-vip.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  exports:[RoomRoutingModule,RoomComponent,RoomAddComponent,RoomModifyComponent],
  declarations: [RoomAddComponent, RoomModifyComponent, RoomComponent, RoomVipComponent]
})
export class RoomModule { }
