import { Component,Output,Input, OnInit, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  private _datacount:number=0;
  private _pagesize:number=15;
  @Output() onPageChange:EventEmitter<any>=new EventEmitter();
  //@Input() datacount:number;
  @Input()
  set datacount(value:number){
    console.log('pagination datacount='+value);
    this._datacount=value;
    this.currentPage=1;
    this.getPageCount();
    this.rebuildPagers();
  }
  get datacount(){
    return this._datacount;
  }
  @Input()
  set pagesize(value:number){
    this._pagesize=value;
  }
  get pagesize(){
    return this._pagesize;
  }

  pages:Array<any>=[];
  pageCount:number=0;
  interval:any;
  constructor() { 
  }
  // public get datacount(){
  //   return this._datacount;
  // }
  // public set datacount(datacount:number){
  //   this._datacount=datacount;
  //   this.getPageCount();
  //   this.rebuildPagers();
  // }
  
  // public get pagesize(){
  //   return this._pagesize;
  // }
  // public set pagesize(pagesize:number){
  //   this._pagesize = pagesize;
  //   this.getPageCount();
  //   this.rebuildPagers();
  // }
  private currentPage:number=1;
  private showPageCount:number=10;

  
  ngOnInit() {
    this._datacount=this.datacount;
    this.getPageCount();
    this.rebuildPagers();
  }
  rebuildPagers(){
    console.log('rebuildPagers');
    this.pages = [];
    if (this.pageCount <= this.showPageCount) {
      for (let i = 1; i <= this.pageCount; i++) {
          this.pages.push({
              index: i,
              active: i == this.currentPage,
              text: i.toString()
          });
      }
    }
    else {
      let beforeCount = Number(Math.floor((this.showPageCount - 5) / 2));
      if (this.currentPage - beforeCount <= 2) {
        for (let i = 1; i <= 8; i++) {
          this.pages.push({
            index: i,
            active: i == this.currentPage,
            text: i.toString()
          });
        }
        this.pages.push({
          index: 0,
          active: false,
          text: '...'
        });
        this.pages.push({
          index: this.pageCount,
          active: false,
          text: this.pageCount.toString()
        });
      }
      else if (this.currentPage + beforeCount >= (this.pageCount - 1)) {
          console.log(this.currentPage + beforeCount);
          this.pages.push({
              index: 1,
              active: false,
              text: '1'
          });
          this.pages.push({
              index: 0,
              active: false,
              text: '...'
          });
          for (let i = this.pageCount - 7; i <= this.pageCount; i++) {
              this.pages.push({
                  index: i,
                  active: i == this.currentPage,
                  text: i.toString()
              });
          }
      }
      else {
          this.pages.push({
              index: 1,
              active: false,
              text: '1'
          });
          this.pages.push({
              index: 0,
              active: false,
              text: '...'
          });
          for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
              this.pages.push({
                  index: i,
                  active: i == this.currentPage,
                  text: i.toString()
              });
          }
          this.pages.push({
              index: 0,
              active: false,
              text: '...'
          });
          this.pages.push({
              index: this.pageCount,
              active: false,
              text: this.pageCount.toString()
          });
        }
    }
  }
  getPageCount(){
    this.pageCount = Math.ceil(this._datacount / this._pagesize);
    this.pageCount = this.pageCount || 1;
  }
  pageChange(pageindex){
    if (pageindex > this.pageCount || pageindex < 1) {
      return;
    }
    this.currentPage = Number(pageindex);
    this.rebuildPagers();
    this.onPageChange.emit(this.currentPage);
  }
  onPageInputChange(pageindex){
    let _this = this;
    clearTimeout(this.interval);
    if (pageindex > this.pageCount || pageindex < 1)
        return;
    this.interval = setTimeout(function () {
        _this.pageChange(pageindex);
    }, 1000);
  }
}
