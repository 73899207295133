import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluateComponent } from '../evaluate/evaluate.component';
import { DetailComponent } from '../detail/detail.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path: 'evaluate',
      component: EvaluateComponent
  },
  {
      path: 'evaluate/detail/:id',
      component: DetailComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ]
})
export class EvaluateRoutingModule { }
