import { Injectable } from '@angular/core';
import { HttpService } from '../shared/service/http.service';
import { StorageService } from '../shared/service/storage.service';
import { MessageService } from '../shared/service/message.service';
import { LoadingService } from '../shared/service/loading.service';
import { Router } from '@angular/router';
import { ConfirmService } from '../shared/service/confirm.service';
import { Subject } from 'rxjs';
import AppHttpApiURL from 'src/config/app.url.config';
import  appSettings from 'src/config/appsetting.config';

@Injectable()
export class AccountService {
  private account$:Subject<any>=new Subject();
  constructor(
    private httpService:HttpService,
    private storageService:StorageService,
    private messageService:MessageService,
    private loadingService:LoadingService,
    private router:Router,
    private confirmService:ConfirmService
  ) { 

  }
  get(){
    return this.account$.asObservable();
  }
  login(user){
    let _this = this;
    if (!user.username || !user.password) {
        this.messageService.open('信息不完整', 'error');
        return;
    }
    this.loadingService.open();
    this.httpService.get(AppHttpApiURL.account.login,{...user}).subscribe(data=>{
      if(data.code>0){
        _this.messageService.open('登陆成功', 'success');
        _this.storageService.update({
            username: data.data.manager.UserName,
            token: data.data.token
        });
        _this.router.navigate(['/welcome']);
        _this.account$.next({ isSuccess: true });
      }else{
        _this.messageService.open(data.message, 'error');
        _this.account$.next({ isSuccess: false });
      }
      _this.account$.next(data);
      _this.loadingService.close();
    });
  }
  getImgCodeUrl(){
    let url = '/imagecode?a=' + Math.random().toString();
    return 'http://' + appSettings.CrossProxyURL + url;
  }
}
