import { Component,Output,Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-btn-item',
  templateUrl: './dropdown-btn-item.component.html',
  styleUrls: ['./dropdown-btn-item.component.css']
})
export class DropdownBtnItemComponent implements OnInit {
  @Input() btnText:string;
  @Input() btnValue:number;
  @Output() onClick:EventEmitter<any>=new EventEmitter();
  innerText:string;
  constructor() { }

  ngOnInit() {
    this.innerText=this.btnText;
  }
  onBtnClick(){
    this.onClick.emit(this.btnValue);
  }

}
