import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LimitComponent } from '../limit/limit.component';
import { LockTimeComponent } from '../lock-time/lock-time.component';
import { ResetTimeComponent } from '../reset-time/reset-time.component';
import { SuperpwdComponent } from '../superpwd/superpwd.component';
import { RouterModule } from '@angular/router';
import { TipsComponent } from '../tips/tips.component';
import { LimitvipComponent } from '../limitvip/limitvip.component';

const routes = [
  {
      path: 'set',
      component: LimitComponent
  },
  {
      path: 'set/limit',
      component: LimitComponent
  },
  {
    path: 'set/limitvip',
    component: LimitvipComponent
  },
  {
      path: 'set/locktime',
      component: LockTimeComponent
  },
  {
      path: 'set/resettime',
      component: ResetTimeComponent
  },
  {
      path: 'set/superpwd',
      component: SuperpwdComponent
  },
  {
    path:'set/tips',
    component:TipsComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ],
  declarations: []
})
export class SetRoutingModule { }
