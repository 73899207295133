import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrizeComponent } from '../prize/prize.component';
import { PrizeAddComponent } from '../prize-add/prize-add.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path: 'prize',
      component: PrizeComponent
  },
  {
      path: 'prize/add',
      component: PrizeAddComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ],
  declarations: []
})
export class PrizeRoutingModule { }
