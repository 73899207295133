import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LimitComponent } from './limit/limit.component';
import { LockTimeComponent } from './lock-time/lock-time.component';
import { ResetTimeComponent } from './reset-time/reset-time.component';
import { SuperpwdComponent } from './superpwd/superpwd.component';
import { SetRoutingModule } from './set-routing/set-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TipsComponent } from './tips/tips.component';
import { LimitvipComponent } from './limitvip/limitvip.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [LimitComponent, LockTimeComponent, ResetTimeComponent, SuperpwdComponent, TipsComponent, LimitvipComponent],
  exports:[SetRoutingModule,LimitComponent,LockTimeComponent,ResetTimeComponent,LimitvipComponent]
})
export class SetModule { }
