import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestBookComponent } from '../guestbook/guestbook.component';
import { DetailComponent } from '../detail/detail.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

const routes = [
  {
    path: 'guestbook',
    component: GuestBookComponent
  },
  {
    path: 'guestbook/detail/:id',
    component: DetailComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ]
})
export class GuestBookRoutingModule { }
