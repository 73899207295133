import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SetService } from '../set.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-limit',
  templateUrl: './limitvip.component.html',
  styleUrls: ['./limitvip.component.css']
})
export class LimitvipComponent implements OnInit {
  private subscription:Subscription;
  minutes:number;
  constructor(
    private route:ActivatedRoute,
    private setService:SetService
  ) { 
    let _this=this;
    this.subscription = this.setService.get().subscribe(function (data) {
      _this.minutes = data.limitVipTime;
    });
  }

  ngOnInit() {
    this.setService.getTimeLimitVIP();
  }
  onSubmit(){
    this.setService.modifyLimitVIP(this.minutes);
  }
  
}
