import { Component, OnInit } from '@angular/core';
import { RoomService } from '../room.service';
import { MessageService } from 'src/app/shared/service/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-room-add',
  templateUrl: './room-add.component.html',
  styleUrls: ['./room-add.component.css']
})
export class RoomAddComponent implements OnInit {
  private subscription:Subscription
  room={
    roomname: '',
    roomaddress: '',
    roomdesc: '',
    enteruid: '',
    leaveuid: '',
    viewid: '',
    sn: 'sn',
    limitcount: undefined
  };
  constructor(
    private roomService:RoomService,
    private messageService:MessageService
  ) { 
    this.subscription = this.roomService.get().subscribe(function (data) {
      if (data.isSuccess) {
          for (const key in this.room) {
            if (Object.hasOwnProperty(key)) {
              this.room[key]='';
              
            }
          }
      }
  });

  }

  ngOnInit() {
  }
  onSubmit(){
    var error = false;
    for (const key in this.room) {
      if (Object.hasOwnProperty(key)) {
        if (this.room[key]=='') {
          this.messageService.open('信息不完整，请确认！', 'error');
          error = true;
          break;
        }
      }
    }
    if (error) {
        return;
    }
    this.roomService.AddRoom({...this.room});
  }
  
}
