import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsService, Tab } from './home/tabs/tabs.service';
import { ConfirmService, Confirm } from './shared/service/confirm.service';
import { LoadingService } from './shared/service/loading.service';
import { StorageService } from './shared/service/storage.service';
import { Subscription } from 'rxjs';
import { MessageService } from './shared/service/message.service';
import { SimpleReuseStrategy } from './shared/class/simple-reuse-strategy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:[SimpleReuseStrategy]
})
export class AppComponent {
  private tabs:Tab[];
  private subscription: Subscription;
  private alertSubscription: Subscription;
  private confirmSubScription: Subscription;
  private messageSubscription:Subscription;
  private loadingSubscription: Subscription;
  private selectorSubscription:Subscription;
  private alert = null;
  private confirm:Confirm={
    active:false,
    operate:undefined,
    content:''
  };
  private message={
    active:false
  };
  private loading={
    active:false
  };;
  private breadCrumbs=null;
  constructor(
    private router:Router,
    private tabsService:TabsService,
    private confirmService:ConfirmService,
    private messageService:MessageService,
    private loadingService:LoadingService,
    private storageService:StorageService,
    private activateRoute:ActivatedRoute,
  ){
    this.subscription=this.tabsService.get().subscribe((breadCrumbs)=>{
      this.breadCrumbs=breadCrumbs;
    });
    this.messageSubscription=this.messageService.get().subscribe(message=>{
      this.message=message;
    });
    this.confirmSubScription=this.confirmService.get().subscribe(confirm=>{
      this.confirm=confirm;
    });
    this.loadingSubscription=this.loadingService.get().subscribe(loading=>{
      this.loading=loading;
    });
  }

  ngOnInit(){
    this.breadCrumbs=this.tabsService.all();
    this.loading={active:false};
    let token=localStorage.getItem('token');
    if(!token){
      this.router.navigate(['/account/login']);
    }else{
      this.storageService.update({
        token:token,
        username:localStorage.getItem('username')
      });
      this.router.navigate(['/welcome']);
    }
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.confirmSubScription.unsubscribe();
    this.selectorSubscription.unsubscribe();
  }
  closeConfirm(){
    this.confirmService.close();
  }
}
