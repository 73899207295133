import { Component,Output,Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  @Input() confirmContent:string='';
  @Output() onOk:EventEmitter<any>=new EventEmitter();
  @Output() onCancel:EventEmitter<any>=new EventEmitter();

  constructor() { 
  }

  ngOnInit() {
  }
  onOkClick(){
    this.onOk.emit();
    this.onCancel.emit();
  }
  onCancelClick(){
    this.onCancel.emit();
  }


}
