import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestBookComponent } from './guestbook/guestbook.component';
import { DetailComponent } from './detail/detail.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GuestBookRoutingModule } from './guestbook-routing/guestbook-routing.module';

@NgModule({
  declarations: [GuestBookComponent, DetailComponent],
  imports: [
    CommonModule,FormsModule,SharedModule
  ],
  exports:[GuestBookComponent,DetailComponent,GuestBookRoutingModule]
})
export class GuestBookModule { }
