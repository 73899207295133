import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomAddComponent } from '../room-add/room-add.component';
import { RoomModifyComponent } from '../room-modify/room-modify.component';
import { RoomComponent } from '../room.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { RoomVipComponent } from '../room-vip/room-vip.component';

const routes = [
  {
      path: 'room',
      component: RoomComponent
  },
  {
      path: 'room/add',
      component: RoomAddComponent
  },
  {
      path: 'room/modify/:id',
      component: RoomModifyComponent
  },
  {
    path: 'room/vip/:id',
    component: RoomVipComponent
}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes)
  ],
  declarations: []
})
export class RoomRoutingModule { }
