import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SetService } from '../set.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-limit',
  templateUrl: './limit.component.html',
  styleUrls: ['./limit.component.css']
})
export class LimitComponent implements OnInit {
  private subscription:Subscription;
  minutes:number;
  constructor(
    private route:ActivatedRoute,
    private setService:SetService
  ) { 
    let _this=this;
    this.subscription = this.setService.get().subscribe(function (data) {
      _this.minutes = data.limitTime;
      console.log('this.minutes', _this.minutes);
    });
  }

  ngOnInit() {
    this.setService.getTimeLimit();
  }
  onSubmit(){
    this.setService.modifyLimit(this.minutes);
  }
  
}
