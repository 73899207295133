import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContentComponent } from './content/content.component';
import { HeaderComponent } from './header/header.component';
import { TabsComponent } from './tabs/tabs.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { MenuComponent } from './menu/menu.component';

@NgModule({
  declarations: [ContentComponent,HeaderComponent,MenuComponent,TabsComponent,WelcomeComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[ContentComponent,HeaderComponent,MenuComponent,TabsComponent,WelcomeComponent]
})
export class HomeModule { }
