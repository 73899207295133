import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDatetime'
})
export class FormatDatetimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value)return '';
    let dateStr = '';
    let date = new Date(parseInt(value.substr(6)));
    if (date.getFullYear() <= 1900) {
        return '';
    }
    if (!args) {
        dateStr = date.toLocaleString();
    }
    else {
        dateStr = args;
        dateStr = dateStr.replace('yyyy', date.getFullYear().toString());
        dateStr = dateStr.replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'));
        dateStr = dateStr.replace('dd', date.getDate().toString().padStart(2, '0'));
        dateStr = dateStr.replace('HH', date.getHours().toString().padStart(2, '0'));
        dateStr = dateStr.replace('mm', date.getMinutes().toString().padStart(2, '0'));
        dateStr = dateStr.replace('ss', date.getSeconds().toString().padStart(2, '0'));
    }
    return dateStr; 
  }

}
